.service-content-top {
    padding: 100px 0 70px;
    @media (max-width: 767px) {
        padding: 80px 0 50px;
    }
    ul:not(.redactor-toolbar) {
        @include unorderedListStyles();
    } 
}

.service-content-bottom {
    background: $navy;
    padding: 100px 0;
    @media (max-width: 767px) {
        padding: 80px 0;
    }
    &__img-container {
        @media (min-width: 768px) {
            height: 0;
        }
    }
    img {
        margin-bottom: 30px;
        @media (min-width: 768px) {
            position: relative;
            top: -150px;
            margin-bottom: 0;
        }
    }
    h2 {
        color: $white;
    }
    .small-hr {
        margin-bottom: 30px;
    }
    ul {
        @include unorderedListStyles();
        margin-bottom: 30px;
    }    
    a {
        @include button();
        color: $white;
        display: inline-block;
        padding: 11px 60px;
        border: 2px solid $blue;
        &::after {
            color: $blue;
        }
        &:hover, &:focus, &:active {
            color: $white;
        }
    }
}

