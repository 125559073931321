.site-header {
    background: transparent;
    color: $white;
    position: fixed;
    top: 0;
    z-index: 10;
    padding: 20px 0 0 0;
    width: 100%;
    &--blue, &--blue-hard {
        background: $navy;
    }
    @media (max-width: 991px) {
        padding: 0px;
    }
}

.main-header ~ .site-header {
    top: 50px;
}

.navbar {
    @apply flex;
    @apply flex-wrap;
    @apply items-center;
    @apply justify-between;
    @apply items-start;
    @apply px-0;
    @apply relative;
    @media (max-width: 991px) {
        @apply items-center;
    }
    .active {
        color: $blue;
    }
    &__logo {
        font-size: 24px;
    }
    &__list {
        @apply hidden;
        @apply w-full;
        @apply flex-col;
        @apply mt-8;
        @media (max-width: 991px) {
            @apply mt-0 mb-4;
        }

        @screen lg {
            @apply flex;
            @apply flex-row;
            @apply w-auto;
        }

        ul {
            @apply flex;
            align-items: flex-start;
            @apply list-none;
            @media (max-width: 991px) {
                flex-direction: column;
            }
            li { // dropdown parent link
                position: relative; // dropdown positioned relative to parent link
                padding: 0 30px;
                height: 50px;
                font-size: 16px;
                color: $white;
                @media (max-width: 1199px) {
                    padding: 0 15px;
                }
                @media (min-width: 992px) {
                    &:after {
                        content: '';
                        width: 1px;
                        height: 20px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        background-color: $white;
                    }
                }
                &:first-of-type {
                    padding-left: 0;
                }
                &:last-of-type {
                    padding-right: 0;
                    &:after {
                        display: none;
                    }
                }
                a {
                    transition: bottom 0.2s 0.2s;
                    padding-bottom: 26px;
                    color: $white;
                    display: block;
                    position: relative;
                    bottom: 0;
                    @media (max-width: 991px) {
                        padding-bottom: 0px;
                    }
                    &:hover, &:active, &:focus {
                        bottom: 10px;
                        padding-bottom: 36px;
                        @media (max-width: 991px) {
                            padding-bottom: 0px;
                            bottom: 0;
                        }
                        &:before {
                            content: '';
                            width: 100%;
                            height: 1px;
                            position: absolute;
                            bottom: 36px;
                            left: 0;
                            background-color: $blue;
                            @media (max-width: 991px) {
                                display: none;
                            }
                        }
                    }
                }
                &.navbar__social-icon {
                    margin-right: 10px;
                }
                &:last-of-type {
                    margin-right: 0;
                }

                @media (max-width: 991px) {
                    padding: 10px 0;
                    height: auto;
                }

                ul { // dropdown menu
                    background-color: transparent;
                    opacity: 0;
                    display: none;
                    flex-direction: column;
                    top: 50px;
                    left: -15px;
                    width: 250px;
                    z-index: 11;
                    @media (max-width: 991px) {
                        left: 0;
                    }

                    @screen md {
                        position: absolute;
                        padding: 10px 30px;
                        border-bottom: 2px solid $blue;
                        background: rgba(9,19,54,0.9);
                    }

                    .all-services-link {
                        @media (min-width: 768px) {
                            display: none;
                        }
                    }
                    li { // dropdown menu links
                        margin-right: 0;
                        padding: 10px 0 0 15px !important;
                        line-height: 32px;
                        &:after {
                            display: none;
                        }

                        @screen lg {
                            padding: 0;
                        }

                        a {
                            color: $white;
                            transition: all 0.2s;
                            &:hover, &:focus, &:active {
                                color: $blue;
                                padding-bottom: 26px;
                                bottom: 0;
                            }
                            &:before {
                                display: none;
                            }

                            &[aria-expanded=true] {
                                @media (max-width: 992px) {
                                  padding-bottom: 0px;
                                }
                            }
                        }

                        @media (min-width: 992px) {
                          ul {
                            position: absolute;
                            top: -10px;
                            left: 220px;
                          }
                        }
                    }

                }
            }
        }
    }
}

.angle-after {
    &::after {
        content: '\f107';
        font-family: "Font Awesome 5 Pro";
        margin-left: 5px;
        display: inline-block;
        line-height: 1;
    }
}

a[aria-expanded=false] {
    &::after {
        transform: rotate(0);
        transition: transform 150ms ease-out;
    }
}
a[aria-expanded=true] {
    &::after {
        transform: rotate(-180deg);
        transition: transform 150ms ease-in;
    }
}

// nav search desktop

.nav-search {
    a {
        color:white;
        transition: all 0.2s;
        &:hover, &:focus, &:active {
            color: $blue;
        }
    }
    &__container {
        position: relative;
        @media (max-width: 991px) {
            display: none;
        }
    }
    &__button {
        font-size: 24px;
        line-height: 24px;
        margin-left: 50px;
        transition: all 0.2s;
        cursor: pointer;
        color: $blue;
        &:hover, &:focus, &:active {
            color: $white;
        }
    }
    &__input {
        border-radius: 5px;
        border: 1px solid $blue;
        color: $white;
        background: $navy;
        padding-left: 10px;
        width: 0;
        position: absolute;
        top: -10px;
        height: 40px;
        right: 0;
        display: none;
        font-family: 'Mont Book';
        &::placeholder {
            font-family: 'Font Awesome 5 Pro';
            font-size: 24px;
            line-height: 40px;
            font-weight: 300;
            position: absolute;
            top: 7px;
            color: $white;
        }
    }
}


// nav search mobile

.nav-search {
    a {
        color:white;
        transition: all 0.2s;
        &:hover, &:focus, &:active {
            color: $blue;
        }
    }
    &__container--mobile {
        position: absolute;
        top: 15px;
        right: 70px;
        @media (min-width: 992px) {
            display: none;
        }
    }
    &__button--mobile {
        font-size: 24px;
        line-height: 24px;
        margin-left: 50px;
        transition: all 0.2s;
        cursor: pointer;
        color: $white;
        &:hover, &:focus, &:active {
            color: $white;
        }
    }
    &__input--mobile {
        border-radius: 5px;
        border: 1px solid $blue;
        color: $white;
        background: $navy;
        padding-left: 10px;
        width: 0;
        position: absolute;
        top: -5px;
        height: 40px;
        right: 0;
        display: none;
        font-family: 'Mont Book';
        z-index: 10;
        &::placeholder {
            font-family: 'Font Awesome 5 Pro';
            font-size: 24px;
            line-height: 40px;
            font-weight: 300;
            position: absolute;
            top: 7px;
            color: $white;
        }
    }
}

.mobile-only-link {
    @media (min-width: 992px) {
        display: none;
    }
}

.header-tel {
    @apply absolute top-0 text-blue text-lg;
    left: 258px;
    @media (max-width: 1200px) {
        left: 231px;
    }
    @media (max-width: 991px) {
        left: auto;
        right: 13px;
        top: 20px;
    }
}

@media (max-width: 991px) {
    .mob-t {
        margin-top: 40px;
    }
}
