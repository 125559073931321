.product-hero {
    @media (max-width: 600px) {
        padding-bottom: 60px;
        h1 {
            font-size: 30px;
            line-height: 38px;
        }
    }
}

.product-content-top {
    padding: 100px 0 60px;
    @media (max-width: 767px) {
        padding: 80px 0 30px;
    }
    ul {
        @include unorderedListStyles();
    }
    a:not(.re-button):not([data-toggle="tooltip"]) {
        background: $blue;
        color: $white;
        padding: 10px 40px;
        width: 188px;
        font-family: 'Mont SemiBold';
        font-size: 14px;
        line-height: 26px;
        transition: all 0.2s;
        border: 1px solid $blue;
        margin-right: 50px;
        display: inline-block;
        position: relative;
        border-radius: 3px;
        @media (max-width: 991px) {
            margin-right: 15px;
        }
        @media (max-width: 440px) {
            margin-right: 0;
            &:first-of-type {
                margin-bottom: 30px;
            }
            width: 100%;
        }
        i, em {
            position: absolute;
            right: 40px;
            top: 15px;
            transition: all 0.2s;
        }
        &:hover, &:focus, &:active {
            background: $white;
            color: $blue;
            i, em {
                color: $blue;
                right: 30px;
            }
        }
    }
}

.product-content-bottom {
    padding: 0 0 150px;
    @media (max-width: 767px) {
        padding: 0 0 80px;
    }
    ul {
        @include unorderedListStyles();
    }
    a:not([data-toggle="tooltip"]) {
        background: $blue;
        color: $white;
        padding: 10px 40px;
        width: 188px;
        font-family: 'Mont SemiBold';
        font-size: 14px;
        line-height: 26px;
        transition: all 0.2s;
        border: 1px solid $blue;
        margin-right: 50px;
        display: inline-block;
        position: relative;
        border-radius: 3px;
        @media (max-width: 991px) {
            margin-right: 15px;
        }
        @media (max-width: 440px) {
            margin-right: 0;
            &:first-of-type {
                margin-bottom: 30px;
            }
            width: 100%;
        }
        i, em {
            position: absolute;
            right: 40px;
            top: 15px;
            transition: all 0.2s;
        }
        &:hover, &:focus, &:active {
            background: $white;
            color: $blue;
            i, em {
                color: $blue;
                right: 30px;
            }
        }
    }
    h3 {
        color: $navy;
        margin-bottom: 40px;
    }
    table {
        font-family: 'Mont Book';
        font-size: 14px;
        line-height: 35px;
        width: 100%;
        color: $grey;
        tr:nth-child(odd) {
            background: #f8f8f9;
        }
        td {
            width: 50%;
            padding: 2px 0 2px 20px;
        }
    }
}

.key-features {
    p {
        color: $blue;
    }
    ul {
        @include unorderedListStyles();
    }
}

#main-product-img {
    margin-bottom: 30px;
}

.product-image-slider {
    @media (max-width: 767px) {
        margin-bottom: 30px;
    }
    @media (min-width: 1200px) {
        width: 370px;
    }
    position: relative;
    img {
        cursor: pointer;
    }
    &__next-arrow {
        position: absolute;
        right: -70px;
        top: 40px;
        @media (max-width: 1199px) {
            right: -15px;
            bottom: 0;
        }
        @media (max-width: 991px) {
            display: none !important;
        }
        i, em {
            color: $blue;
            font-size: 20px;
            line-height: 30px;
            font-weight: 400;
        }
    }
}
