.hero {
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    height: 600px;
    width: 100vw;
    display: flex;
    align-items: flex-end;
    padding-bottom: 110px;
    @media (max-width: 767px) {
        height: 500px;
    }
    a {
        @include arrowSliderButton();
        transition: all 0.2s;
        &:hover, &:focus, &:active {
            color: $white;
        }
    }
}