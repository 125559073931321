h1 {
    font-family: 'Mont Bold';
    font-size: 60px;
    line-height: 70px;
    color: $white;
    margin-bottom: 20px;
    @include respond(mobile) {
        font-size: 42px;
        line-height: 50px;
    }
}

h2 {
    font-family: 'Mont SemiBold' !important;
    font-size: 36px;
    line-height: 50px;
    color: $navy;
    margin-bottom: 30px;
    @include respond(mobile) {
        font-size: 24px;
        line-height: 40px;
    }
}

h3 {
    font-family: 'Mont SemiBold';
    font-size: 24px;
    line-height: 30px;
    color: $white;
    margin-bottom: 10px;
    @include respond(mobile) {
        font-size: 20px;
        line-height: 30px;
    }
}

h4 {
    font-family: 'Mont Book';
    font-size: 24px;
    line-height: 28px;
    color: $white;
}

h5 {
    font-family: 'Mont SemiBold';
    font-size: 15px;
    line-height: 28px;
    color: $white;
    margin-bottom: 30px;
}

h6 {
    font-family: 'Mont Book';
    font-size: 14px;
    line-height: 26px;
    color: $white;
    margin-bottom: 20px;
}

p {
    font-family: 'Mont Book';
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 30px;
    color: $grey;
}


