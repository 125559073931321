.home-hero {
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-position: center;
    height: 800px;
    h1 {
        margin-bottom: 15px;
        @media (max-width: 991px) {
            margin-top: 95px;
        }
    }
}

.home-hero-overlay {
    background-image: url('../../../../public/images/layout/home-hero-overlay.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-position-y: bottom;
    p {
        color: $white;
        line-height: 28px;
        margin-bottom: 35px;
    }
    a:not([data-toggle="tooltip"]) {
        @include button();
        display: inline-block;
        padding: 11px 40px;
    }
}


.home-sales-links {
    position: relative;

    background: $navy;
    .sales-links {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: auto auto auto auto;
        position: relative;
        bottom: 100px;
        @media (max-width: 991px) {
            bottom: 170px;
        }
        @media (min-width: 1800px) {
            bottom: 180px;
        }
        @media (min-width: 2300px) {
            bottom: 200px;
        }
        @media (max-width: 991px) {
            grid-template-columns: auto auto;
        }
        @media (max-width: 410px) {
            grid-template-columns: auto;
        }

            .sales-link {
                color: $white;
                background: $blue;
                padding: 0 30px 45px 35px;
                transition: all 0.2s;

                @media (min-width: 1199px) {
                    width: 285px;
                    height: 180px;
                }
                @media (max-width: 991px) {
                    padding: 0 20px 55px;
                }
                &:hover, &:focus, &:active {
                    background: $white;
                    h3 {
                        color: $blue;
                    }
                    &::after {
                        background: $blue;
                    }
                    .cls-1 {
                        stroke: $blue !important;
                    }
                }
                &:last-of-type {
                    margin-right: 0;
                }
                position: relative;
                &::after {
                    content: '';
                    display: block;
                    height: 1.2px;
                    width: 50px;
                    background: $white;
                    @media (max-width: 440px) {
                        position: absolute;
                        left: calc(50% - 25px);
                    }
                }
                h3 {
                    @media (min-width: 441px) {
                        width: 142px;
                    }
                    margin-top: 55px;
                    @media (max-width: 440px) {
                        text-align: center;
                    }
                }
                .sales-icon {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    @media (min-width: 1200px) {
                        top: 30px;
                        right: 30px;
                    }
                    @media (max-width: 440px) {
                        display: none;
                    }
                }
            }
        .new-generators-icon {
            width: 30px;
        }
        .used-generators-icon, .pricetag-icon {
            width: 57px;
        }
        .repairs-icon {
            width: 43px;
        }
    }
}


.introduction-to-freeman {
    background: $navy;
    margin-top: -180px;
    .row {
        padding: 180px 0 110px;
        @media (max-width: 991px) {
            position: relative;
            z-index: 1;
            padding-top: 50px;
        }
        @media (max-width: 767px) {
            padding-bottom: 70px;
        }
    }
    h2 {
        color: $white;
        margin-bottom: 50px;
        @media (min-width: 768px) {
            max-width: 650px;
        }
        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
        span {
            &:first-of-type {
                font-family: 'Mont Light';
            }
            &:last-of-type {
                font-family: 'Mont Bold';
            }
        }
    }
    &__text {
        p:last-of-type {
            margin-bottom: 20px;
        }
        @media (min-width: 768px) {
            max-width: 470px;
        }
    }
    img {
        @media (max-width: 767px) {
            display: none;
        }
    }
    ul:not(.redactor-toolbar) {
        a {
            transition: all 0.2s;
            color: rgba(255, 255, 255, 0.5);
            align-self: flex-end;
            font-family: 'Red Hat Display';
            font-size: 14px;
            line-height: 26px;
            float: right;
            @media (min-width: 768px) {
                display: none;
            }
            div {
                display: inline-block;
            }
            i, em {
                font-size: 20px;
                line-height: 24px;
                color: $blue;
                transition: all 0.2s;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 1px solid rgba(255, 255, 255, 0.5);
                margin-left: 10px;
            }
        }
    }
}


.generator-sales {
    background-image: url('../../../../public/images/layout/blue-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0 100px;
    @media (max-width: 767px) {
        padding: 40px 0 100px;
    }
    h2, p {
        color: $white;
    }
    h2 {
        margin-bottom: 20px;
    }
    .small-hr, .small-hr--expanding {
        @media (max-width: 767px) {
            margin-bottom: 30px;
        }
    }
    p {
        @media (max-width: 767px) {
            display: none;
        }
    }
    &__img {
        background-blend-mode: multiply;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 20px;
        box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.53);
    }
    h3 {
        margin-bottom: 20px;
        &:last-of-type {
            @media (max-width: 767px) {
                margin-top: 30px;
            }
        }
    }
    a {
        &:hover, &:active, &:focus {
            .small-hr--expanding {
                width: calc(100% - 75px);
            }
            span {
                background: $white;
                color: $blue;
                $border-color: $white;
            }
        }
    }
    span {
        float: right;
        @include arrowButtonStyles();
        margin-top: 10px;
        margin-right: 20px;
        @media (max-width: 767px) {
            display: none;
        }
    }
    ul:not(.redactor-toolbar) {
        li {
            margin-bottom: 10px;
            a {
                font-family: 'Mont SemiBold';
                color: rgba(255, 255, 255, 0.6);
                transition: all 0.2s;
                &::after {
                    content: '\f178';
                    font-family: 'Font Awesome 5 Pro';
                    font-size: 16px;
                    line-height: 24px;
                    margin-left: 10px;
                    transition: all 0.2s;
                }
                &:hover, &:focus, &:active {
                    color: $white;
                    &::after {
                        color: $white;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}

