#cookies-bar, #cookies-bar-tab {
    color: $white;
    background: $navy;
    position: fixed;
    bottom: 0;
    border: 1px solid $white;
    z-index: 10;
}

#cookies-bar {
    padding: 60px 0;
    p, h5 {
        color: $white;
    }
    a {
        color: $blue;
        transition: all 0.2s;
        &:hover, &:focus, &:active {
            color: #7dc352;
        }
    }
}

#cookies-bar-tab {
    padding: 5px 10px;
    left: 0;
    cursor: pointer;
}