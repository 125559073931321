@tailwind base;

@tailwind components;

@import 'imports/config';
@import 'imports/layout.scss';
@import 'imports/forms.scss';
@import 'imports/buttons.scss';
@import 'imports/slider.scss';
@import 'imports/typography';
@import 'imports/error';
@import 'imports/components/hero';
@import 'imports/components/nav';
@import 'imports/components/footer';
@import 'imports/components/hamburger';
@import 'imports/components/contact-tab';
@import 'imports/components/privacy-notice';
@import 'imports/components/cta-banner';
@import 'imports/components/industry-partners';
@import 'imports/components/pagination';
@import 'imports/pages/home';
@import 'imports/pages/about';
@import 'imports/pages/services';
@import 'imports/pages/service';
@import 'imports/pages/sales';
@import 'imports/pages/products';
@import 'imports/pages/product';
@import 'imports/pages/two-col';
@import 'imports/pages/search-results';
@import 'imports/pages/contact';
@import 'imports/pages/terms';
@import 'imports/pages/404';
//@import 'sass/diagnostics.scss';

@tailwind utilities;