@mixin arrowButtonStyles() {
  color: $white;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.6);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
}

@mixin button() {
  font-family: 'Mont SemiBold';
  font-size: 14px;
  line-height: 24px;
  color: $white;
  border: 2px solid $blue;
  padding: 15px 40px;
  transition: all 0.2s;
  &::after {
      content: '\f178';
      font-family: 'Font Awesome 5 Pro';
      font-size: 20px;
      line-height: 24px;
      color: $blue;
      margin-left: 10px;
      position: relative;
      top: 3px;
      left: 0;
      transition: all 0.2s;
  }
  &:hover, &:focus, &:active {
      border-color: $white;
      background: $blue;
      &::after {
          color: $white;
          left: 10px;
      }
  }
}

@mixin arrowSliderButton() {
    color: $grey;
    pointer-events: auto;
    display: flex;
    align-items: center;
    font-family: 'Red Hat Display';
    &:hover, &:active, &:focus {
        div {
            background: $white;
        }
    }
    div {
        background: transparent;
        transition: all 0.2s;
        margin-left: 10px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 1px solid $grey;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            color: $blue;

        }
    }
}

.arrow-button {
    @apply font-display font-medium text-white;
    @apply inline-flex gap-x-10px items-center;

    font-size: 14px;

    i {
        @apply text-center;
        @apply border border-white rounded-full;

        font-size: 20px;
        line-height: 38px;
        width: 2em;
        color: $blue;
    }

    @screen md {
        @apply hidden;
    }
}