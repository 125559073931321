// RESETS

h1, h2, h3, h4, h5, h6, ol, ul, li, blockquote, p, a, img, a img, hr, fieldset, form, table, tbody, tr, td, input[type=checkbox], input[type=radio], textarea, select, button { margin:0; padding:0; text-decoration:none; border:0 none; }
strong, b { font-weight:700;}
html, html a { -webkit-font-smoothing: antialiased !important; -moz-osx-font-smoothing: grayscale; }
a[href^=tel] { color: inherit; text-decoration: none; }
button:active, button:focus, input:active, input:focus, a:active, a:focus { outline:none; }
@media screen and (max-width:$tablet) {
    a[href^=mailto] { word-break: break-all }
}

body, html {
    @apply bg-white;
    @apply text-black;
    @apply font-normal;
    font-family: 'Helvetica', 'Arial', sans-serif;
	font-size:16px;
    line-height:1.5rem;
    overflow-x: hidden;
}

a {
    font-family: 'Mont Book';
    @apply no-underline;
    &:hover {
        @apply no-underline;
    }
}



// GLOBAL PLACEHOLDERS

%centre { text-align:center; margin-left:auto; margin-right:auto; }
%shadow { box-shadow:inset 0 5px 12px -5px rgba(0,0,0,0.3); }

// CONTENT

div#map {
    @apply w-full;
    height: 500px;
    @screen lg {
        @apply h-screen;
    }
}

.small-hr {
    width: 70px;
    margin-bottom: 60px;
    transition: all 0.2s;
    &--white {
        border-top: 2px solid $white;
    }
    &--blue {
        border-top: 2px solid $blue;
    }
}

.small-hr--expanding {
    border-top: 1px solid $white;
    width: 70px;
    transition: all 0.2s;
    margin-top: 30px;
    &:hover, &:focus, &:active {
        width: calc(100% - 80px);
    }
}

@mixin unorderedListStyles() {
    color: $grey;
    padding-inline-start: 0;
    margin-left: -5px;
    font-family: 'Mont Book';
    font-size: 14px;
    margin-bottom: 30px;
    li {
        position: relative;
        padding-left: 25px;
        &::before {
            content: '\f111';
            font-family: 'Font Awesome 5 Pro';
            color: $blue;
            font-weight: 900;
            margin-right: 10px;
            font-size: 9px;
            position: absolute;
            left: 5px;
        }
    }
}

.redactor-toolbar li:before {
    content: none !important;
}
