.error-page {
    min-height: 100vh;
    background: url('../../../../public/images/layout/404-bg-80.jpg'), url('../../../../public/images/layout/404-overlay-2.png');
    background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        @media (min-width: 1200px) {
            margin-top: 120px;
        }
    }
    p {
        color: $white;
    }
    a {
        @include button();
        display: inline-block;
        padding: 11px 60px;
        width: auto;
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    .row {
        --bs-gutter-x: 0;
    }
}