/* COLOURS */

$grey: #909596;
$white: #FFFFFF;
$black:#000000;
$navy: #091336;
$navy-dark: #060e23;
$blue: #079fcf;
$trans-white: rgba(255, 255, 255, 0.7);
$green: #7dc352;
$dark-text: #1c1f2a;
$checkbox-grey: #5A5A5A;


/* FONTS */

@font-face {
    font-family: 'Mont Bold';
    src: url('../../../public/fonts/mont/Mont-Bold.otf') format('woff2');
} 

@font-face {
    font-family: 'Mont SemiBold';
    src: url('../../../public/fonts/mont/Mont-SemiBold.otf') format('woff2');
}

@font-face {
    font-family: 'Mont Book';
    src: url('../../../public/fonts/mont/Mont-Book.otf') format('woff2');
}

@font-face {
    font-family: 'Mont Light';
    src: url('../../../public/fonts/mont/Mont-Light.otf') format('woff2');
}


@font-face {
    font-family: 'Nexa Regular';
    src: url('../../../public/fonts/nexa/Nexa-Regular.otf') format('woff2');
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../../../public/fonts/red-hat-display/RedHatDisplay-Medium.ttf') format('woff2');
}


//MIXINS

/* BREAKPOINTS */

$desktop: 1199px;
$tablet: 991px;
$mobile: 767px;
$xs: 575px;

$desktop-min: 1200px;
$tablet-min: 992px;
$mobile-min: 768px;
$xs-min: 576px;

@mixin respond($breakpoint) {
  @if $breakpoint == xs {
      @media screen and (max-width: $xs) { @content };
  }
  @if $breakpoint == mobile {
      @media screen and (max-width: $mobile) { @content };
  }
  @if $breakpoint == tablet {
      @media screen and (max-width: $tablet) { @content };
  }
  @if $breakpoint == desktop {
      @media screen and (max-width: $desktop) { @content };
  }
}

@mixin respond-min($breakpoint) {
  @if $breakpoint == xs {
      @media screen and (min-width: $xs-min) { @content };
  }
  @if $breakpoint == mobile {
      @media screen and (min-width: $mobile-min) { @content };
  }
  @if $breakpoint == tablet {
      @media screen and (min-width: $tablet-min) { @content };
  }
  @if $breakpoint == desktop {
      @media screen and (min-width: $desktop-min) { @content };
  }
}