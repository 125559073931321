.pagination {
    padding: 50px 0 100px;
    @media (max-width: 767px) {
        padding: 30px 0 80px;
    }
    a {
        color: $blue;
        font-family: 'Mont SemiBold';
        font-size: 14px;
        line-height: 24px;
        i, em {
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $grey;
            color: $blue;
            transition: all 0.2s;
        }
        &:hover, &:focus, &:active {
            color: $dark-text;
            i, em {
                background: $blue;
                color: $dark-text;
                border-color: $dark-text;
            }
        }
    }
    a:first-of-type {
        i, em {
            margin-right: 10px;
        }
    }
    a:last-of-type {
        i, em {
            margin-left: 10px;
        }
    }
}