@mixin slickArrowStyles() {
    background-repeat: no-repeat;
    background-size: cover;
    width: 40px !important;
    height: 40px !important;
    cursor: pointer;
    position: absolute;
    bottom: -90px;
    top: auto !important;
    z-index: 9;
    border: 1px solid $grey;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        color: $navy;
        font-size: 20px;
        line-height: 24px;
    }
    &:hover, &:focus, &:active {
        width: 50px;
        height: 50px;
        border-radius:50%;
        box-shadow: 0 0 0 3px $blue;
    }
    &::before {
        content: '' !important;
    }
}
// key services block
.key-services {
    padding: 100px 0 130px;
    h2 {
        margin-bottom: 30px;
        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }
    .small-hr {
        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }
    &__text {

            a:not(.re-button) {
                float: right;
                font-family: 'Mont Book';
                color: $navy-dark;
                display: flex;
                align-items: center;
                transition: all 0.2s;
                margin: 20px 0 50px;
                @media (max-width: 767px) {
                    display: none;
                }
                &:hover, &:focus, &:active {
                    color: $blue;
                    i {
                        background: $blue;
                        color: $white;
                        border-color: $navy-dark;
                    }
                }
                i {
                    border-radius: 50%;
                    color: $navy-dark;
                    border: 1px solid $grey;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    line-height: 24px;
                    margin-left: 10px;
                    transition: all 0.2s;
                }
            }

    }
}

// key services slider
.key-services {
    overflow-x: hidden;
    padding-bottom: 140px;
    @media (max-width: $tablet) {
        padding-bottom: 105px;
    }
    &__explore-link--desktop {
        display: inline-block;
        margin-bottom: 40px;
    }
    p {
        @media (max-width: 991px) {
            margin-bottom: 40px;
        }
    }
    @media (max-width: $tablet) {
        padding-top: 60px;
    }
    @media (max-width: 767px) {
        padding-top: 40px;
    }
    &__explore-link {
        &--desktop {
            @media (max-width: $tablet) {
                display: none;
            }
        }
        &--mobile {
            display: none;
            @media (max-width: $tablet) {
                display: inline-block;
                color: $grey;
                font-size: 16px;
                margin-top: 20px;
            }
        }
    }

    // slider

    &__img-overlay {
        background: url('../../../public/images/layout/slider-overlay.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: calc(100% - 30px);
        height: 100%;
        position: absolute;
        left: 15px;
        top: 0;
    }

    &__overlay {
        background-color: rgba(9, 19, 54, 0.9);
        padding: 140px 50px 50px;
        opacity: 0;
        pointer-events: none;
        transition: all 1.2s;
        width: calc(100% - 30px);
        height: 100%;
        position: absolute;
        left: 15px;
        top: 0;
        p {
            color: $white;
            font-size: 12px;
        }
        span {
            @include arrowSliderButton();
            position: absolute;
            bottom: 50px;
            right: 50px;
            @media (max-width: 767px) {
                bottom: 40px;
                right: 30px;
            }
        }
        .text {
            @media (max-width: 1200px) {
                display: none !important;
            }
        }
    }
    &__img {
        width: 100%;
    }

    &__wrapper {
        position: relative;
        .key-services__button--next {
            @include slickArrowStyles();
            left: 50px;
            bottom: -60px;
            @media (max-width: $tablet) {
                left: 50%;
                bottom: -65px;
            }
        }
        .key-services__button--prev {
            @include slickArrowStyles();
            left: 0;
            bottom: -60px;
            @media (max-width: $tablet) {
                left: calc(50% - 50px);
                bottom: -65px;
            }
        }

        .slick-list {
            padding: 0 405px 0 0;
            @media screen and (min-width: 1200px) {
                overflow: visible;
            }
        }
    }

    &__inner {
        margin-left: -15px;
    }


    &__item {
        padding: 0 15px;
        cursor: pointer;
        position: relative;

        // @media (min-width: 1200px) and (max-width: 1700px) {
        //     width: 470px !important;
        //     height: 470px;
        // }


        .link-box {
            h3 {
                font-family: 'Mont Book';
                margin-bottom: 0;
                .small-hr--blue {
                    margin-top: 30px;
                    margin-bottom: 0;
                    width: 70px;
                }
            }
            &__text-wrap {
                .slide-text {
                    position: absolute;
                    left: 65px;
                    //bottom: 50px;
                    top: calc(100% - 110px);
                    color: $white;
                    transition: all 0.7s;
                    @media (max-width: 767px) {
                        left: 45px;
                        top: calc(100% - 100px);
                    }
                    h3 {
                        @media (max-width: 767px) {
                            font-size: 24px;
                            line-height: 28px;
                        }
                    }
                }
            }
            &__link {
                pointer-events: auto;
                &:hover, &:focus, &:active {
                    .small-hr--blue {
                        width: 100%;
                    }
                    .slide-text {
                        // move up
                        top: 50px;
                    }
                }

            }
        }


        &:hover, &:focus, &:active {
            .key-services__overlay {
                opacity: 1;
            }
        }
        &.slick-active {
            opacity: 1;
        }
    }

    @include respond(desktop) {

        &__title-wrap {
            padding-right: 95px;
        }

        &__wrapper {
            padding: 0;

            .slick-list {
                padding: 0;
            }
        }

        &__inner {
            margin: 0 -15px;
        }

        &__item {
            opacity: 1;
        }



    }

}


