.industry-partners {
    padding: 100px 0 70px;
    @media (max-width: 767px) {
        padding: 40px 0 50px;
    }
    h2 {
        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }
    .small-hr {
        @media (max-width: 767px) {
            margin-bottom: 30px;
        }
    }
    &__logos {
        display: flex;
        justify-content: space-between;
        @media (max-width: 1199px) {
            overflow-x: scroll;
        }
        img + img {
            margin-left: 30px;
        }
    }
}
