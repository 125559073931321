.about-content-top {
    padding: 100px 0 310px;
    @media (max-width: 991px) {
        padding: 100px 0 210px;
    }
    @media (max-width: 767px) {
        padding: 70px 0 40px;
    }
}

.about-content-bottom {
    background: $navy;
    padding: 360px 0 70px;
    @media (max-width: 991px) {
        padding: 260px 0 70px;
    }
    @media (max-width: 767px) {
        padding: 70px 0;
    }
    h2 {
        color: $white;
    }
    hr {
        margin-bottom: 30px;
    }
    &__img-main {
        @media (min-width: 768px) {
            height: 0;
        }
        img {
            position: relative;
            top: -610px; // minus half the height of the image and the about-content-bottom top padding
            @media (max-width: 1199px) {
                top: -566px;
            }
            @media (max-width: 991px) {
                top: -417px;
            }
            @media (max-width: 767px) {
                position: static;
                margin-bottom: 30px;
            }
        }
    }
    ul:not(.redactor-toolbar) {
        @include unorderedListStyles();
    }
}
