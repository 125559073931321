.services-blocks {
    padding: 100px 0;
}
.services-block {
    &__text {
        p {
            margin-bottom: 90px;
        }
    }
    &__block-2-title {
        padding-top: 60px;
    }
    .service-link {
        background-blend-mode: multiply;
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
        position: relative;
        margin-bottom: 30px;
        &::before {
            content: '';
            background-color: rgba(9, 19, 54, 0.75);
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 1;
            pointer-events: none;
            transition: all 0.2s;
        }
        h3 {
            display: inline-block;
            position: absolute;
            bottom: 30px;
            left: 40px;
            z-index: 2;
            pointer-events: none;
        }
        hr {
            width: 70px;
            border-top: 1px solid $white;
            transition: all 0.2s;
            margin-top: 20px;
        }
        &__text-block {
            position: absolute;
            left: 40px;
            top: 40px;
            span, p, h3 {
                position: static;
            }
            h3, p {
                color: $white;
            }
            p {
                font-family: 'Nexa Regular';
                margin-bottom: 10px;
                font-weight: 700;
                @media (max-width: 379px) {
                    font-size: 12px;
                    margin-bottom: 5px;
                    line-height: 14px;
                }
            }
            hr {
                margin-bottom: 20px;
                @media (max-width: 379px) {
                    margin-bottom: 10px;
                }
            }

            span {
                display: inline;
            }
            &-contact-btn {
                position: absolute;
                right: 40px;
                bottom: 30px;
                span, i, em, p {
                    position: static !important;
                }
                p {
                    display: inline;
                    color: rgba(255, 255, 255, 0.5);
                    transition: all 0.2s;
                    font-family: 'Red Hat Display';
                }
                span {
                    display: inline-flex !important;
                    margin-left: 10px;
                }
            }
        }
        span {
            position: absolute;
            right: 40px;
            bottom: 30px;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(255, 255, 255, 0.5);;
            transition: all 0.2s;
            z-index: 2;
            i, em {
                font-size: 20px;
                line-height: 24px;
                color: $white;
                transition: all 0.2s;
            }
        }
        &:hover, &:focus, &:active {
            hr {
                width: 100%;
            }
            &::before {
                opacity: 1;
            }
            span {
                background: $white;
                i, em {
                    color: $grey;
                    border-color: $white;
                }
            }
        }
    }
}

.service-link--cta {
    &:hover, &:focus, &:active {
        .service-link__text-block-contact-btn {
            p {
                color: $white;
            }
        }
    }
    .service-link__text-block {
        z-index: 2;
        &-contact-btn {
            z-index: 2;
            p {
                color: rgba(255, 255, 255, 0.5);
            }
            @media (max-width: 379px) {
                display: none;
            }
        }
        h3 {
            max-width: calc(100% - 60px);
        }
    }

}