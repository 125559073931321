
 .products-content-block {
    padding: 100px 0 50px;
    @media (max-width: 767px) {
        padding: 80px 0 30px;
    }
    &__text {
        p {
            margin-bottom: 30px;
            @media (max-width: 767px) {
                margin-bottom: 0;
            }
            &:last-of-type {
                margin-bottom: 0px;
            }
        }
    }
    .small-hr {
        margin-bottom: 0px;
    }
}

.products {
     &__sort-container {
         padding-bottom: 50px;
         display: flex;
         align-items: center;
         @media (max-width: 767px) {
             padding-bottom: 0;
         }
     }
     &__item-count {
         color: $grey;
         margin-bottom: 0;
         @media (max-width: 767px) {
             margin-bottom: 30px;
         }
         span {
             color: $blue;
         }
     }
     .product-select-container {
        position: relative;
        @media (max-width: 767px) {
            margin-bottom: 30px;
        }
        &::before {
            content: '\f107';
            font-family: 'Font Awesome 5 Pro';
            font-size: 18px;
            line-height: 20px;
            color: $checkbox-grey;
            position: absolute;
            right: 15px;
            top: 14px;
            pointer-events: none;
            font-weight: 300;
        }
     }
    select {
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance: none;
        background: $white;
        border: 2px solid #f4f4f5;
        border-radius: 2px;
        line-height: 24px;
        font-size: 14px;
        font-family: 'Mont SemiBold';
        color: $blue;
        width: 100%;
        padding: 10px 0px 10px 10px;
    }
}


// filter panel

#product-category {
    margin-top: 60px;
}

.filter-box {
    background: #f8f8f9;
    padding: 20px;
    font-family: 'Mont Book';
    margin-bottom: 30px;
    #filter-group-manufacturer {
        h5 {
            margin-top: 40px;
        }
    }
    h5 {
        color: $dark-text;
        margin-bottom: 20px;
    }
    label {
        font-weight: 300;
        font-size: 14px;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid $checkbox-grey;
    }

    &__label {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 10px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

  /* On mouse-over, add a grey background color */
  &__label:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  &__label input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  &__label .checkmark:after {
      content: "\f00c";
      font-family: "Font Awesome 5 Pro";
      left: 3px;
      top: -2px;
      color: $checkbox-grey;
  }
    h6:first-of-type {
        margin: 0 0 20px;
    }
    h6 {
        font-size: 18px;
        margin: 20px 0;
    }
    &__list-item {
        color: $checkbox-grey;
        font-size: 14px;
        &:hover, &:active, &:focus {
            text-decoration: none;
        }
    }
    &__option-text {
        color: $checkbox-grey;
    }
}

// cta box

.cta-box {
    display: flex;
    flex-direction: column;
    background-image: url('../../../../public/images/layout/sales-cta.png');
    // background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px 15px 30px;
    transition: all 0.2s;
    margin-bottom: 60px;
    @media (max-width: 991px) {
        display: none;
    }
    h4 {
        font-family: 'Mont SemiBold';
        margin-bottom: 20px;
    }
    .small-hr {
        margin-bottom: 20px;
    }
    p {
        color: $white;
        font-family: 'Nexa Regular';
        font-weight: 700;
        margin-bottom: 10px;
        @media (max-width: 1199px) {
            display: none;
        }
    }
    span {
        transition: all 0.2s;
        color: rgba(255, 255, 255, 0.5);
        align-self: flex-end;
        font-family: 'Red Hat Display';
        font-size: 14px;
        line-height: 26px;
        @media (max-width: 1199px) {
            align-self: flex-start;
        }
        i, em {
            font-size: 20px;
            line-height: 24px;
            color: $white;
            transition: all 0.2s;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(255, 255, 255, 0.5);
            margin-left: 10px;
        }
    }
    &:hover, &:focus, &:active {
        background-image: url('../../../../public/images/layout/sales-cta.png');
        background-color: rgba(9, 19, 54, 0.75);
        background-blend-mode: multiply;
        span {
            color: $white;
            i, em {
                background: $white;
                color: $blue;
            }
        }
    }
}

// product thumbnails

.product-thumbnail {
    display: block;
    border: 2px solid #f4f4f5;
    margin-bottom: 30px;
    &:focus, &:hover, &:active {
        box-shadow: -1px -1px 21px 1px rgba(0,0,0,0.3);
        .product-thumbnail__more-info {
            color: $dark-text;
            i, em {
                background: $blue;
                color: $dark-text;
                border-color: $dark-text;
            }
        }
        .product-thumbnail__details {
            background: #ccc;
        }
        .product-thumbnail__attribute, .product-thumbnail__value {
            color: $dark-text;
        }
        .product-thumbnail__title > h5 {
            color: $blue;
        }
    }
    &__title {
        padding: 10px 20px;
        h5 {
            transition: all 0.2s;
            color: $dark-text;
            margin-bottom: 0;
        }
    }
    &__details {
        background: #f8f8f9;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        transition: all 0.2s;
        ul {
            list-style-type: none;
            column-count: 2;
        }

    }
    &__attribute, &__value {
        font-size: 13px;
        line-height: 15px;
        color: $grey;
        transition: all 0.2s;
    }
    &__attribute {
        font-family: 'Mont SemiBold';
    }
    &__value {
        font-family: 'Mont Book';
    }
    li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    &__more-info {
        float: right;
        color: $blue;
        font-family: 'Mont SemiBold';
        font-size: 14px;
        line-height: 24px;
        transition: all 0.2s;
        align-self: flex-end;
        i, em {
            margin-left: 10px;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $grey;
            color: $blue;
            transition: all 0.2s;
        }

    }
}
