.terms-content {
    padding: 100px 0;
    @media (max-width: 767px) {
        padding: 80px 0;
    }
    a {
        color: $blue;
        transition: all 0.2s;
        &:hover, &:focus, &:active {
            color: $green;
        }
    }
    ul, ol {
        @apply font-normal;
        margin-bottom: 30px;
    }
    ul:not(.redactor-toolbar) {
        @include unorderedListStyles();
    }
    h3, h4, h5, h5 {
        color: $grey;
    }
}
