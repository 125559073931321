.sales-block {
    padding: 100px 0 60px;
    @media (max-width: 767px) {
        padding: 80px 0 50px;
    }
    &__text {
        p {
            margin-bottom: 30px;
        }
    }
    .sales-link {
        background-blend-mode: multiply;
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
        position: relative;
        margin-bottom: 30px;
        &::before {
            content: '';
            background-color: rgba(7, 159, 207, 0.9);
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 1;
            pointer-events: none;
            transition: all 0.2s;
        }
        h3 {
            display: inline-block;
            position: absolute;
            bottom: 30px;
            left: 40px;
            z-index: 2;
            pointer-events: none;
            font-size: 22px;
        }
        hr {
            width: 70px;
            border-top: 2px solid $blue;
            transition: all 0.2s;
            margin-top: 20px;
        }
        &:hover, &:focus, &:active {
            hr {
                width: 100%;
                border-color: $white;
            }
            &::before {
                opacity: 1;
            }
        }
    }
}

.sales-enquiry-form {
    @include contactFormStyles();
}

.why-buy {
    h3 {
        @apply text-blue;
    }
}
