// styles for capabilities and transport pages

.two-col-content-top {
    padding: 100px 0 70px;
    @media (max-width: 767px) {
        padding: 80px 0 50px;
    }
    .small-hr {
        margin-bottom: 30px;
        @media (min-width: 768px) {
            margin-bottom: 0;
        }
    }
}

.content-block {
    margin-bottom: 100px;
    @media (max-width: 767px) {
        margin-bottom: 80px;
    }
    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .small-hr {
            margin-bottom: 30px;
        }

        h2 {
            font-family: 'Mont Bold';
        }

        ul, li {
            font-family: 'Mont Book';
        }

        ul:not(.redactor-toolbar) {
            font-size: 14px;
            line-height: 26px;
            margin-bottom: 30px;
            color: $grey;
            position: relative;
            padding-left: 20px;

            li:before {
                content: "\f111";
                font-family: "Font Awesome 5 Pro";
                color: $blue;
                font-weight: 900;
                margin-right: 10px;
                font-size: 9px;
                position: absolute;
                left: 5px;
            }
        }
    }
}

.image-blocks {
    &--item {
        @apply max-w-full h-auto;
    }
}

@media (max-width: 767px) {
    .image-blocks {
        &--item:not(:first-child) {
            @apply mt-6;
        }
    }
}
