
.grecaptcha-badge {
    z-index: 20;
}

.contact-form {
    background: $navy;
    padding: 100px 0 !important;
    @media (max-width: 767px) {
        padding: 80px 0 !important;
    }
    h2 {
        color: $white;
    }
    .small-hr {
        margin-bottom: 40px;
    }
    @include contactFormStyles();
    textarea {
        min-height: 240px;
    }
    &__contact-details {
        font-family: 'Mont Book';
        font-size: 14px;
        line-height: 26px;
        color: $grey;
        address {
            font-style: normal;
            margin-bottom: 24px;
        }

        p {
            color: $blue;
        }
        p:nth-of-type(1), p:nth-of-type(2) {
            margin-bottom: 0;
        }
        p:nth-of-type(3), p:nth-of-type(4) {
            color: $grey;
            margin-bottom: 24px;
        }
        a {
            display: block;
            color: $blue;
            margin-bottom: 24px;
            transition: all 0.2s;
            &:hover, &:focus, &:active {
                color: $green;
            }
        }
    }
    button[type="submit"], input[type="submit"] {
        @media (max-width: 767px) {
            margin-bottom: 30px;
        }
    }
}

.help-block {
    font-family: 'Mont SemiBold';
    color: rgb(223, 71, 89);
    margin-bottom: 30px;
    display: block;
}

.map {
    #map {
        height: 400px;
    }
    #maps-infowindow > p {
        color: $navy;
    }

    .maps-marker {
        z-index: 3 !important;
        width: 60px;
    }
}