.contact-tab {
    cursor: pointer;
    position: fixed;
    top: 290px;
    right: -276px;
    z-index: 100;
    background: $blue;
    color: $white;
    display: flex;
    flex-direction: column;
    padding: 40px 30px 10px;
    box-shadow: -5px 1px 12px 0px rgba(0,0,0,0.55);
    @media (max-width: 991px) {
        display: none;
    }
    span {
        font-size: 16px;
        margin-left: 15px;
        font-family: 'Mont Semibold';
        display: inline-block;
        margin-bottom: 30px;
    }

    a:not(.re-button) {
        display: flex;
        transition: all 0.2s;
        &:hover, &:focus, &:active {
            color: $navy;
        }
        i, em {
            font-size: 24px;
            line-height: 22px;
        }
    }
    &__icon {
        p {
            color: $white;
            margin-bottom: 0;
        }
    }
}
