.footer {
    background: $navy;
    padding-bottom: 20px;
    @media (max-width: 767px) {
        display: none;
    }
    p {
        font-size: 12px;
        line-height: 26px;
    }
    hr {
        border-top: 2px solid rgba(255, 255, 255, 0.2);
        margin: 100px 0 50px;
        width: 100%;
    }
    font-style: normal;
    address {
        font-style: normal;
        color: $grey;
        margin-bottom: 10px;
        font-family: 'Mont Book';
        font-size: 12px;
        line-height: 26px;
        &:first-of-type {
            margin-right: 30px;
            width: 120px;
        }
    }
    img {
        width: 170px;
        margin-top: 20px;
    }
    h5 {
        margin-bottom: 10px;
    }
    &__address {
        display: flex;
        p {
            display: inline;
            margin-bottom: 0;
            color: $blue;
        }
    }

    &__contact-details {
        p {
            color: $white;
            font-size: 14px !important;
            margin-bottom: 10px;
            a {
                transition: all 0.2s;
                &:hover, &:focus, &:active {
                    color: $green;
                }
            }
        }
    }
}

.social-icons {
    a {
        @apply text-white mt-6;
        &:hover {
            @apply text-blue;
        }
        i {
            @apply text-3xl;
        }
        &+a {
            @apply ml-2;
        }
    }
}



.proto-footer {
    background: $navy-dark;
    padding: 10px 0;
    font-family: 'Nexa Regular';
    &__content {
        a {
            transition: all 0.2s;
            &:hover, &:focus, &:active {
                color: $green;
            }
        }
        @media (max-width: 375px) {
            div {
                display: flex;
                justify-content: center;
                text-align: center;
                margin-bottom: 10px;
                flex-direction: column;
                align-items: center;
                img {
                    margin-bottom: 10px;
                }
            }
        }
        @media (max-width: 562px) {
            flex-direction: column;
            align-items: center;
        }
    }
    img {
        width: 13px;
        height: 20px;
        margin-right: 10px;
        display: inline-block;
    }
    p {
        color: $white;
        display: inline-block;
        margin: 0;
        font-family: 'Nexa Regular';
    }
}
