@mixin contactFormStyles() {
    padding: 100px 0 85px;
    @media (max-width: 767px) {
        padding: 80px 0 65px;
    }
    background: $navy;
    h2 {
        color: $white;
    }
    h4 {
        margin-bottom: 40px;
        &:last-of-type {
            margin-top: 30px;
        }
    }
    input, textarea, select {
        width: 100%;
        background: $navy-dark;
        color: $white;
        margin-bottom: 30px;
        padding: 10px 20px 8px;
        border-bottom: 2px solid transparent;
        transition: all 0.2s;
        font-family: 'Mont SemiBold';
        font-size: 14px;
        &:hover, &:focus, &:active {
            border-color: $blue;
            outline: none;
        }
        &::placeholder {
            color: $grey;
            font-family: 'Mont Light';
            font-size: 14px;
        }
    }
    &__select-container {
        position: relative;
        &::before {
            content: '\f107';
            font-family: 'Font Awesome 5 Pro';
            font-size: 16px;
            line-height: 26px;
            color: $grey;
            position: absolute;
            right: 55px; 
            top: 9px;
        }
    }
    textarea {
        min-height: 200px;
    }
    select {
        color: $grey;
        font-family: 'Mont Light';
        // remove browser select styles
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance: none;
        font-size: 14px;
    }
    button[type="submit"] {
        @include button();
        color: $white;
        display: inline-block;
        padding: 11px 60px;
        border: 2px solid $blue;
        &::after {
            color: $blue;
        }
        &:hover, &:focus, &:active {
            color: $white;
        }
    } 
}

p.error, p.success, .help-block {
    font-family: 'Mont SemiBold';
    color: $white;
    background-color: #df4759;
    padding: 5px 10px;

    ul, li {
        color: $white !important;
        font-size: 14px !important;
    }
}

p.success {
    background-color: $green;
}