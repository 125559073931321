.cta-banner {
    position: relative;
    .container {
        @media (max-width: 767px) {
            position: relative;
            z-index: 1;
        }
    }
    &__mobile-banner-overlay {
        background-color: rgba(0, 161, 212, 0.9);
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        @media (min-width: 768px) {
            display: none;
        }
    }
    padding: 80px 0 65px;
    @media (min-width: 768px) {
        background-image: url('../../../../public/images/layout/cta-banner-80.jpg'), url('../../../../public/images/layout/cta-banner-overlay.png');
    }
    background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: right;
    @media (max-width: 767px) {
        background-image: url('../../../../public/images/layout/cta-banner-80.jpg');
        background-position-x: 79%;
        padding: 40px 0 50px;
    }
    h2, p {
        color: $white;
    }
    h2 {
        margin-bottom: 20px;
    }
    .small-hr {
        margin-bottom: 30px;
        @media (min-width: 768px) {
            display: none;
        }
    }
    &__link {
        @include button();
        color: $trans-white;
        display: inline-block;
        padding: 11px 60px;
        border: 1px solid $white;
        &::after {
            color: $trans-white;
        }
        &:hover, &:focus, &:active {
            color: $white;
        }
        @media (max-width: 767px) {
            font-family: 'Red Hat Display';
            border: none;
            padding: 0;
            &::after {
                margin-left: 10px;
                border: 1px solid $trans-white;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}



