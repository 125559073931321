.search-results {
    padding-top: 100px;
    @media (max-width: 767px) {
        padding-top: 80px;
    }
    .small-hr {
        margin-bottom: 0;
    }
    .search-term {
        color: $blue;
    }
}



.search-results-list {
    h4 {
        color: $blue;
    }
}

// styles used for list of links

.list {
    padding: 60px 0 0;
    a:first-of-type {
        border-top: 2px solid rgba(214,214,214, 0.3);
    }
    a:last-of-type {
        border-bottom: 2px solid rgba(214,214,214, 0.3);
    }
}

.list-item {
    display: block;
    padding: 25px 0;
    border-top: 2px solid rgba(214,214,214, 0.15);
    border-bottom: 2px solid rgba(228, 227, 227, 0.15);
    margin-bottom: -2px;
    transition: all 0.2s;
    &::marker {
        color: transparent;
    }
    h4 {
        color: $navy;
        transition: all 0.2s;
        width: 100%;
        display: flex;
        align-items: center;
        i {
            margin-left: auto;
            font-size: 24px; 
            line-height: 24px;
            color: #d6d6d6;
            transition: all 0.2s;
        }
    }
    p {
        margin-top: 10px;
        transition: all 0.2s;
    }
    span {
        font-family: 'Mont SemiBold';
        font-size: 30px;
        line-height: 40px;
        color: $navy;
        margin-right: 50px;
        @media (max-width: $xs) {
            margin-right: 20px;
        }
    }
    &:hover, &:focus, &:active {
        background: rgba(214,214,214, 0.3);
        h4 {
            margin-left: 20px;
            i {
                margin-right: 40px;
            }
        }
        p {
            margin-left: 20px;
        }
    }
}

